import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// анимация hero блок
document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.author__hero')) {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from('.author__hero__wrapper__photo img', {
      scrollTrigger: {
        trigger: '.author__hero',
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
      opacity: 0,
      y: 50,
      duration: 1,
    });

    const infoElements = gsap.utils.toArray('.author__hero__wrapper__info > *');

    gsap.from(infoElements, {
      scrollTrigger: {
        trigger: '.author__hero',
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.2,
    });
  }
});

// анимация about me блок
document.addEventListener('DOMContentLoaded', function () {
  gsap.registerPlugin(ScrollTrigger);

  const aboutMeSection = document.querySelector('.author__aboutme');
  const textElements = document.querySelectorAll(
    '.author__aboutme__desc__label, .author__aboutme__desc__whoiam, .author__aboutme__desc__greeting, .author__aboutme__target__inner__head, .author__aboutme__target__inner__text'
  );
  const counters = document.querySelectorAll('.author__aboutme__desc__statistic__item__counter');
  const targetBlock = document.querySelector('.author__aboutme__target');
  const targetTextElements = document.querySelectorAll(
    '.author__aboutme__target__inner__head, .author__aboutme__target__inner__text'
  );

  if (aboutMeSection && textElements.length > 0 && counters.length > 0) {
    gsap.from(textElements, {
      scrollTrigger: {
        trigger: aboutMeSection,
        start: 'top 80%',
        toggleActions: 'play none none none',
      },
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.2,
    });

    function setCounterWidth(element) {
      const supElement = element.querySelector('sup');
      const numericText = element.innerText
        .replace(supElement ? supElement.innerText : '', '')
        .trim();
      const charCount = numericText.length;
      element.style.width = `${charCount + 1}ch`; // +1 для учета возможного sup
    }

    function animateCounter(element, endValue) {
      const supElement = element.querySelector('sup');
      const supText = supElement ? supElement.outerHTML : '';
      const numericText = element.innerText
        .replace(supElement ? supElement.innerText : '', '')
        .trim();

      gsap.fromTo(
        element,
        {
          innerText: 0,
        },
        {
          innerText: endValue,
          duration: 2,
          ease: 'power1.inOut',
          snap: { innerText: 1 },
          scrollTrigger: {
            trigger: element,
            start: 'top 80%',
            toggleActions: 'play none none none',
          },
          onUpdate: function () {
            element.innerHTML = Math.ceil(element.innerText) + supText;
          },
        }
      );
    }

    counters.forEach((counter) => {
      setCounterWidth(counter);
      const endValue = parseInt(counter.textContent.replace(/\D/g, ''));
      animateCounter(counter, endValue);
    });
  }

  if (targetBlock && targetTextElements.length > 0) {
    gsap.fromTo(
      targetTextElements,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        stagger: 0.2,
        scrollTrigger: {
          trigger: targetBlock,
          start: 'top 80%',
          toggleActions: 'play none none none',
        },
      }
    );
  }
});
