import Swiper from 'swiper/bundle';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// Управление слайдером сегментации клиентов
document.addEventListener('DOMContentLoaded', function () {
  const swiperContainer = document.querySelector('.case1__info__segmentation__slider--container');

  if (swiperContainer) {
    const slides = swiperContainer.querySelectorAll('.swiper-slide');
    const slidesCount = slides.length;

    let slidesPerView = 1;
    let showNavigation = true;
    let showPagination = true;

    if (slidesCount === 1) {
      showNavigation = false;
      showPagination = false;
    } else if (slidesCount === 2) {
      slidesPerView = 2;
      showNavigation = false;
      showPagination = false;
    } else if (slidesCount === 3) {
      slidesPerView = 3;
      showNavigation = false;
      showPagination = false;
    } else if (slidesCount > 3) {
      slidesPerView = 3;
      showNavigation = true;
      showPagination = true;
    }

    const swiperSegmentation = new Swiper('.case1__info__segmentation__slider--container', {
      slidesPerView: slidesPerView,
      spaceBetween: 30,
      navigation: {
        nextEl: '.case1__info__segmentation__slider__control--next',
        prevEl: '.case1__info__segmentation__slider__control--prev',
      },
      pagination: {
        el: '.case1__info__segmentation__slider__pagination',
        clickable: true,
        dynamicBullets: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });

    if (showNavigation) {
      const nextButton = document.querySelector(
        '.case1__info__segmentation__slider__control--next'
      );
      const prevButton = document.querySelector(
        '.case1__info__segmentation__slider__control--prev'
      );
      if (nextButton && prevButton) {
        nextButton.style.display = 'flex';
        prevButton.style.display = 'flex';
      }
    }

    if (showPagination) {
      const pagination = document.querySelector('.case1__info__segmentation__slider__pagination');
      if (pagination) {
        pagination.style.display = 'flex';
      }
    }
  }
});

// Универсальный слайдер изображения для текстоовго блока
document.addEventListener('DOMContentLoaded', function () {
  const swiperContainers = document.querySelectorAll('.case1__text__element__image__swiper');

  swiperContainers.forEach((container) => {
    new Swiper(container, {
      pagination: {
        el: '.case1__text__element__image__swiper__pagination',
        clickable: true,
      },
      loop: true,
      navigation: {
        nextEl: container.querySelector('.case1__text__element__image__swiper__control--next'),
        prevEl: container.querySelector('.case1__text__element__image__swiper__control--prev'),
      },
    });
  });
});

// Анимация для блоков
gsap.registerPlugin(ScrollTrigger);

document.addEventListener('DOMContentLoaded', function () {
  const heroElement = document.querySelector('.case1__hero');

  if (heroElement) {
    const setInitialVisibility = () => {
      gsap.set(
        [
          '.case1__hero h1',
          '.case1__hero__wrapper__info__text',
          '.case1__hero__wrapper__info__links__item',
          '.case1__hero__wrapper__info__btn a',
          '.case1__hero__wrapper__logo img',
          '.case1__hero__statistics__item',
          '.case1__info__general h2',
          '.case1__info__text p',
          '.case1__info__text ul li',
          '.case1__info__indicators__targets h3',
          '.case1__info__indicators__targets__wrapper__item',
          '.case1__info__indicators__start h3',
          '.case1__info__indicators__start__wrapper__item',
          '.case1__info__portrait__wrapper__text h2',
          // '.case1__info__portrait__wrapper__text .case1__info__text p',
          // '.case1__info__portrait__wrapper__text .case1__info__text ul li',
          '.case1__info__portrait__wrapper__img img',
          '.case1__team h2',
          '.case1__team__slider__item',
          '.case1__team__slider__control',
          '.case1__team__slider__pagination',
          '.case1__info__segmentation h2',
          '.case1__info__segmentation__slider__item',
          '.case1__info__segmentation__slider__control',
          '.case1__info__segmentation__slider__pagination__item',
        ],
        { visibility: 'hidden', opacity: 0 }
      );
    };

    setInitialVisibility();

    // Анимация для блока hero
    const animateHero = () => {
      gsap.fromTo(
        '.case1__hero h1',
        { y: -50, opacity: 0, visibility: 'hidden' },
        { duration: 1, y: 0, opacity: 1, visibility: 'visible', ease: 'power3.out' }
      );
      gsap.fromTo(
        '.case1__hero__wrapper__info__text',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 0.5,
        }
      );
      gsap.fromTo(
        '.case1__hero__wrapper__info__links__item',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1,
          stagger: 0.2,
        }
      );
      gsap.fromTo(
        '.case1__hero__wrapper__info__btn a',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1.5,
          stagger: 0.2,
        }
      );
      gsap.fromTo(
        '.case1__hero__wrapper__logo img',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 2,
        }
      );
      gsap.fromTo(
        '.case1__hero__statistics__item',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 2.5,
          stagger: 0.2,
        }
      );
    };

    // Анимация для блока general info
    const animateGeneralInfo = () => {
      gsap.fromTo(
        '.case1__info__general h2',
        { y: -50, opacity: 0, visibility: 'hidden' },
        { duration: 1, y: 0, opacity: 1, visibility: 'visible', ease: 'power3.out' }
      );
      gsap.fromTo(
        '.case1__info__text p',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 0.5,
        }
      );
      gsap.fromTo(
        '.case1__info__text ul li',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1,
          stagger: 0.2,
        }
      );
    };

    // Анимация для блока indicators
    const animateIndicators = () => {
      gsap.fromTo(
        '.case1__info__indicators__targets h3',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
        }
      );
      gsap.fromTo(
        '.case1__info__indicators__targets__wrapper__item',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 0.5,
          stagger: 0.2,
        }
      );
      gsap.fromTo(
        '.case1__info__indicators__start h3',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1,
        }
      );
      gsap.fromTo(
        '.case1__info__indicators__start__wrapper__item',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1.5,
          stagger: 0.2,
        }
      );
    };

    // Анимация для блока portrait
    const animatePortrait = () => {
      gsap.fromTo(
        '.case1__info__portrait__wrapper__text h2',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
        }
      );
      gsap.fromTo(
        '.case1__info__portrait__wrapper__text .case1__info__text p',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 0.5,
        }
      );
      gsap.fromTo(
        '.case1__info__portrait__wrapper__text .case1__info__text ul li',
        { y: -50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          y: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1,
          stagger: 0.2,
        }
      );
      gsap.fromTo(
        '.case1__info__portrait__wrapper__img img',
        { x: 50, opacity: 0, visibility: 'hidden' },
        {
          duration: 1,
          x: 0,
          opacity: 1,
          visibility: 'visible',
          ease: 'power3.out',
          delay: 1.5,
        }
      );
    };

    // Анимация для блока team slider
    const animateTeamSlider = () => {
      const swiperContainer = document.querySelector('.case1__team__slider');

      if (!swiperContainer) return;

      // Основная анимация появления
      const mainAnimation = () => {
        const tl = gsap.timeline({
          defaults: {
            ease: 'power2.out',
          },
        });

        tl.to('.case1__team h2', {
          opacity: 1,
          y: 0,
          visibility: 'visible',
          duration: 0.8,
        })
          .to(
            '.case1__team__slider__item',
            {
              opacity: 1,
              y: 0,
              visibility: 'visible',
              duration: 0.6,
              stagger: {
                each: 0.15,
                ease: 'power1.inOut',
              },
            },
            '-=0.3'
          )
          .to(
            ['.case1__team__slider__control', '.case1__team__slider__pagination'],
            {
              opacity: 1,
              visibility: 'visible',
              duration: 0.4,
            },
            '-=0.2'
          );

        return tl;
      };

      // Инициализация Swiper команда
      const slides = swiperContainer.querySelectorAll('.swiper-slide');
      const slidesCount = slides.length;

      const swiperTeam = new Swiper(swiperContainer, {
        slidesPerView: 1,
        spaceBetween: 30,
        allowTouchMove: true,
        speed: 400,
        navigation: {
          nextEl: '.case1__team__slider__control--next',
          prevEl: '.case1__team__slider__control--prev',
        },
        pagination: {
          el: '.case1__team__slider__pagination',
          clickable: true,
          dynamicBullets: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
            allowTouchMove: true,
          },
          768: {
            slidesPerView: Math.min(2, slidesCount),
            spaceBetween: 30,
            allowTouchMove: slidesCount > 2,
          },
          1024: {
            slidesPerView: Math.min(3, slidesCount),
            spaceBetween: 30,
            allowTouchMove: slidesCount > 3,
          },
        },
      });

      // Функция обновления параметров слайдера
      const updateSwiperParams = () => {
        const windowWidth = window.innerWidth;

        if (windowWidth >= 1024) {
          swiperTeam.params.allowTouchMove = slidesCount > 3;
          if (slidesCount <= 3) {
            swiperTeam.slides.css('transform', 'none');
            swiperTeam.params.virtualTranslate = true;
          } else {
            swiperTeam.params.virtualTranslate = false;
          }
        } else if (windowWidth >= 768) {
          swiperTeam.params.allowTouchMove = slidesCount > 2;
          if (slidesCount <= 2) {
            swiperTeam.slides.css('transform', 'none');
            swiperTeam.params.virtualTranslate = true;
          } else {
            swiperTeam.params.virtualTranslate = false;
          }
        } else {
          swiperTeam.params.allowTouchMove = true;
          swiperTeam.params.virtualTranslate = false;
        }

        swiperTeam.update();
      };

      // Обработка изменения размера окна
      updateSwiperParams();
      window.addEventListener('resize', updateSwiperParams);

      // Анимация при скролле
      ScrollTrigger.create({
        trigger: '.case1__team',
        start: 'top 75%',
        onEnter: () => mainAnimation(),
        once: true,
      });

      // Минимальная анимация для кнопок навигации
      const navButtons = document.querySelectorAll(
        '.case1__team__slider__control--next, .case1__team__slider__control--prev'
      );
      navButtons.forEach((button) => {
        button.addEventListener('click', () => {
          gsap.to(button, {
            scale: 0.95,
            duration: 0.1,
            yoyo: true,
            repeat: 1,
            ease: 'power1.out',
          });
        });
      });
    };

    // Анимация для блока segmentation
    const animateSegmentation = () => {
      const swiperContainer = document.querySelector('.case1__info__segmentation__slider');

      if (!swiperContainer) return;

      // Основная анимация появления
      const mainAnimation = () => {
        const tl = gsap.timeline({
          defaults: {
            ease: 'power2.out',
          },
        });

        tl.to('.case1__info__segmentation h2', {
          opacity: 1,
          y: 0,
          visibility: 'visible',
          duration: 0.8,
        })
          .to(
            '.case1__info__segmentation__slider__item',
            {
              opacity: 1,
              y: 0,
              visibility: 'visible',
              duration: 0.6,
              stagger: {
                each: 0.15,
                ease: 'power1.inOut',
              },
            },
            '-=0.3'
          )
          .to(
            [
              '.case1__info__segmentation__slider__control',
              '.case1__info__segmentation__slider__pagination__item',
            ],
            {
              opacity: 1,
              visibility: 'visible',
              duration: 0.4,
            },
            '-=0.2'
          );

        return tl;
      };

      // Инициализация Swiper
      const slides = swiperContainer.querySelectorAll('.swiper-slide');
      const slidesCount = slides.length;

      const swiperSegmentation = new Swiper(swiperContainer, {
        slidesPerView: 1,
        spaceBetween: 30,
        allowTouchMove: true,
        speed: 400,
        navigation: {
          nextEl: '.case1__info__segmentation__slider__control--next',
          prevEl: '.case1__info__segmentation__slider__control--prev',
        },
        pagination: {
          el: '.case1__info__segmentation__slider__pagination__item',
          clickable: true,
          dynamicBullets: true,
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
            allowTouchMove: true,
          },
          768: {
            slidesPerView: Math.min(2, slidesCount),
            spaceBetween: 30,
            allowTouchMove: slidesCount > 2,
          },
          1024: {
            slidesPerView: Math.min(3, slidesCount),
            spaceBetween: 30,
            allowTouchMove: slidesCount > 3,
          },
        },
      });

      // Функция обновления параметров слайдера
      const updateSwiperParams = () => {
        const windowWidth = window.innerWidth;

        if (windowWidth >= 1024) {
          swiperSegmentation.params.allowTouchMove = slidesCount > 3;
          if (slidesCount <= 3) {
            swiperSegmentation.slides.css('transform', 'none');
            swiperSegmentation.params.virtualTranslate = true;
          } else {
            swiperSegmentation.params.virtualTranslate = false;
          }
        } else if (windowWidth >= 768) {
          swiperSegmentation.params.allowTouchMove = slidesCount > 2;
          if (slidesCount <= 2) {
            swiperSegmentation.slides.css('transform', 'none');
            swiperSegmentation.params.virtualTranslate = true;
          } else {
            swiperSegmentation.params.virtualTranslate = false;
          }
        } else {
          swiperSegmentation.params.allowTouchMove = true;
          swiperSegmentation.params.virtualTranslate = false;
        }

        swiperSegmentation.update();
      };

      // Обработка изменения размера окна
      updateSwiperParams();
      window.addEventListener('resize', updateSwiperParams);

      // Анимация при скролле
      ScrollTrigger.create({
        trigger: '.case1__info__segmentation',
        start: 'top 75%',
        onEnter: () => mainAnimation(),
        once: true,
      });
    };

    // Создаем ScrollTrigger для каждого блока
    ScrollTrigger.create({
      trigger: '.case1__hero',
      start: 'top 75%',
      onEnter: animateHero,
      once: true,
    });

    ScrollTrigger.create({
      trigger: '.case1__info__general',
      start: 'top 75%',
      onEnter: animateGeneralInfo,
      once: true,
    });

    ScrollTrigger.create({
      trigger: '.case1__info__indicators',
      start: 'top 75%',
      onEnter: animateIndicators,
      once: true,
    });

    ScrollTrigger.create({
      trigger: '.case1__info__portrait',
      start: 'top 75%',
      onEnter: animatePortrait,
      once: true,
    });

    ScrollTrigger.create({
      trigger: '.case1__team',
      start: 'top 75%',
      onEnter: animateTeamSlider,
      once: true,
    });

    ScrollTrigger.create({
      trigger: '.case1__info__segmentation',
      start: 'top 75%',
      onEnter: animateSegmentation,
      once: true,
    });
  }
});

// Анимация таблицы показателей
document.addEventListener('DOMContentLoaded', function () {
  // Check if the element exists on the page
  if (document.querySelector('.case1__table')) {
    gsap.registerPlugin(ScrollTrigger);

    // Animate the section container
    gsap.from('.case1__table', {
      opacity: 0,
      y: 50,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.case1__table',
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none none', // Play only once
      },
    });

    // Animate the title
    gsap.from('.case1__table__head', {
      opacity: 0,
      y: -20,
      duration: 0.8,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.case1__table__head',
        start: 'top 90%',
        end: 'bottom 10%',
        toggleActions: 'play none none none', // Play only once
      },
    });

    // Animate the text
    gsap.from('.case1__table__text', {
      opacity: 0,
      y: -20,
      duration: 0.8,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.case1__table__text',
        start: 'top 90%',
        end: 'bottom 10%',
        toggleActions: 'play none none none', // Play only once
      },
    });

    // Animate the table rows
    gsap.from('.case1__table__wrapper__body__row', {
      opacity: 0,
      y: 20,
      duration: 0.6,
      ease: 'power2.out',
      stagger: 0.2,
      scrollTrigger: {
        trigger: '.case1__table__wrapper__body',
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none none', // Play only once
      },
    });
  }
});

// Навигация
document.addEventListener('DOMContentLoaded', function () {
  const accordionButton = document.querySelector('.case1__text__nav__btn__accordion');
  const accordionContent = document.querySelector('.case1__text__nav__content');
  const header = document.querySelector('.header');

  if (!accordionButton || !accordionContent) {
    return;
  }

  accordionContent.classList.add('show');

  gsap.set(accordionContent, { height: 'auto', opacity: 1 });
  let isExpanded = true;

  accordionButton.addEventListener('click', function () {
    isExpanded = !isExpanded;
    accordionButton.setAttribute('aria-expanded', isExpanded);
    accordionButton
      .querySelector('.case1__text__nav__btn__accordion__arrow')
      .classList.toggle('rotated');

    if (isExpanded) {
      gsap.to(accordionContent, {
        height: 'auto',
        opacity: 1,
        duration: 0.5,
        ease: 'power2.inOut',
      });
    } else {
      gsap.to(accordionContent, { height: 0, opacity: 0, duration: 0.5, ease: 'power2.inOut' });
    }
  });

  function handleAnchorClick(e) {
    e.preventDefault();
    const targetId = this.getAttribute('href').substring(1);
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const headerHeight = header ? header.offsetHeight : 0;
      window.scrollTo({
        top: targetElement.offsetTop - headerHeight,
        behavior: 'smooth',
      });
    } else {
      console.error('Target element not found for ID:', targetId);
    }
  }

  setTimeout(() => {
    document.querySelectorAll('.case1__text__nav a').forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });
  }, 100);
});
