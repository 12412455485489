// Скролл к якорю на форму в футере с учетом высоты header
document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('a[href="#open_form_footer"]').addEventListener('click', (event) => {
    event.preventDefault();

    const header = document.querySelector('.header');
    const target = document.getElementById('open_form_footer');

    if (header && target) {
      const headerHeight = header.offsetHeight;
      const targetPosition = target.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = targetPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  });
});
