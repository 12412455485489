// Скролл к якорю на форму в футере с учетом высоты header
document.addEventListener('DOMContentLoaded', () => {
  const scrollLink = document.querySelector('a[href="#open_form_footer"]');
  const header = document.querySelector('.header');
  const target = document.getElementById('open_form_footer');

  if (scrollLink && header && target) {
    scrollLink.addEventListener('click', (e) => {
      e.preventDefault();

      const headerHeight = header.offsetHeight;
      const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
      const offset = targetPosition - headerHeight;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    });
  }
});
