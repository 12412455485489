import './main.scss';
import './import-jquery';
import $ from 'jquery';
import Inputmask from 'inputmask';
import lozad from 'lozad';
import Tabby from 'tabbyjs/dist/js/tabby';
import Swiper from 'swiper/bundle';
import ymaps from 'ymaps';
import Choices from 'choices.js';

import './script_parts/common/_common.js';
import './script_parts/templates/_kadema_rebranding_page';
import './script_parts/templates/_case_extended';
import './script_parts/templates/_personal_page.js';
import './script_parts/templates/parts/_services_cost.js'
import './script_parts/templates/parts/_client_feedback.js'

const alertify = require('alertifyjs/build/alertify.js');
require('@fancyapps/fancybox');

document.addEventListener('DOMContentLoaded', () => {
  // Fancybox в контенте
  if (document.querySelector('.case__solve__text a img')) {
    document.querySelectorAll('.case__solve__text a img').forEach((img) => {
      img.closest('a').addEventListener('click', (evt) => {
        evt.preventDefault();
        $.fancybox.open({
          src: evt.currentTarget.href,
          type: 'image',
        });
      });
    });
  }

  // Вспомогательные функции
  let cyrValue = (str) => {
    return /[^а-яА-ЯёЁ -]/gi.test(str);
  };
  let animatedNumber = (obj, start, end, duration) => {
    let startTimestamp = null;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      let numbers = Math.floor(progress * (end - start) + start).toString();
      let separator = 1;
      let separatedNumber = '';

      for (let n = numbers.length; n > 0; n--) {
        separatedNumber += numbers[n - 1];
        if (separator == 3) {
          separatedNumber += ' ';
          separator = 1;
        } else {
          separator++;
        }
      }

      obj.innerHTML = separatedNumber.split('').reverse().join('');

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };
  let toggleMedia = () => {
    document.querySelectorAll('button.icon-media').forEach((btn) => {
      if (btn.classList.contains('open')) {
        document.querySelectorAll('.open').forEach((el) => el.classList.remove('open'));
      } else {
        document.querySelectorAll('.icon').forEach((el) => el.classList.add('open'));
      }
    });
  };

  document.querySelectorAll('.icon').forEach((el) => {
    el.addEventListener('click', toggleMedia);
  });

  // Аккордеон
  if (document.querySelector('.accordion')) {
    document.querySelectorAll('.accordion').forEach((el) => {
      let button = el.querySelector('button.button');

      button.addEventListener('click', (e) => {
        e.preventDefault();
        e.currentTarget.classList.toggle('is-active');
        if (e.currentTarget.classList.contains('is-active')) {
          e.currentTarget.closest('.accordion').classList.add('is-active');
        } else {
          e.currentTarget.closest('.accordion').classList.remove('is-active');
        }
      });
    });
  }

  // Анимация числа
  if (document.querySelector('.animated-number')) {
    const animationObserver = lozad('.animated-number', {
      load: (el) => {
        let end = Number(el.dataset.end);
        animatedNumber(el, 0, end, 5000);
      },
    });
    animationObserver.observe();
  }

  // Валидация
  if (document.querySelector('input[name="agreement"]')) {
    document.querySelectorAll('input[name="agreement"]').forEach((el) => {
      el.addEventListener('change', (e) => {
        let status = e.target.checked;

        if (status) {
          e.target
            .closest('form')
            .querySelector('input[type="submit"]')
            .removeAttribute('disabled', '');
          e.target.closest('form').querySelector('p.submit').classList.remove('disabled');
        } else {
          e.target
            .closest('form')
            .querySelector('input[type="submit"]')
            .setAttribute('disabled', '');
          e.target.closest('form').querySelector('p.submit').classList.add('disabled');
        }
      });
    });
  }
  if (document.querySelectorAll('input[name="name"]')) {
    document.querySelectorAll('input[name="name"]').forEach((el) => {
      let counter = 0;

      el.addEventListener('keyup', (e) => {
        if (cyrValue(e.target.value)) {
          counter++;

          if (counter > 5) {
            alertify.notify('Внимание! <br> Имя должно быть на русском языке', 'warning', 5);
            counter = 0;
          }
        }

        e.target.value = e.target.value.replace(/[^а-яА-ЯёЁ -]/gi, '');
      });
    });
  }
  if (document.querySelector('input[required]')) {
    document.querySelectorAll('input[required]').forEach((el) => {
      el.addEventListener('blur', (e) => {
        if (e.target.value.length == 0) {
          alertify.notify('Внимание! <br> Поле обязательно для заполнения', 'warning', 5);
        }
      });
    });
  }
  if (
    document.querySelector('input[type="tel"], input[type="text"], input[type="email"], textarea')
  ) {
    document
      .querySelectorAll('input[type="tel"], input[type="text"], input[type="email"], textarea')
      .forEach((el) => {
        el.addEventListener('blur', (e) => {
          if (e.target.value.length > 0) {
            e.target.classList.add('is-filled');
          } else {
            e.target.classList.remove('is-filled');
          }
        });
      });
  }
  if (document.querySelector('input[name="file_attach[]"]')) {
    document.querySelectorAll('input[name="file_attach[]"]').forEach((el) => {
      el.addEventListener('change', (e) => {
        let totalSize = 0;
        Object.keys(e.currentTarget.files).map((objectKey, index) => {
          totalSize = totalSize + e.currentTarget.files[index].size;
        });
        if (Math.round((totalSize / 1048576) * 100) / 100 > 10) {
          el.value = '';
          alertify.notify('Внимание! <br> Максимальный объём вложений - 10 мб.', 'warning', 5);
        } else if (e.currentTarget.files.length > 10) {
          el.value = '';
          alertify.notify('Внимание! <br> Максимальное количество вложений - 10 шт.', 'warning', 5);
        } else {
          e.currentTarget.closest('form').querySelector('#file-name').innerText =
            e.currentTarget.files.length +
            ' шт. (' +
            Math.round((totalSize / 1048576) * 100) / 100 +
            ' мб.)';
          e.currentTarget.closest('form').querySelector('p.filename').classList.add('is-loaded');
        }
      });
      el.closest('form')
        .querySelector('#file-clear')
        .addEventListener('click', (e) => {
          el.value = '';
          e.currentTarget.closest('form').querySelector('p.filename').classList.remove('is-loaded');
          document.querySelector('#file-name').innerText = '';
        });
    });
  }

  // Вкладки
  if (document.querySelector('[data-tabs]')) {
    document.querySelectorAll('[data-tabs]').forEach((el) => {
      let tabsId = el.dataset.tabs,
        tabs = new Tabby('[data-tabs="' + tabsId + '"]');
    });
  }

  // Функция для инициализации карты
  function initializeMap(mapId, dataId) {
    if (document.querySelector(mapId)) {
      const animationObserver = lozad(mapId, {
        load: (el) => {
          let contactsMap,
            myPlacemark,
            balloonContent = document.querySelector(dataId).innerHTML,
            hintContent = document.querySelector(`${dataId} .address`).innerHTML,
            coords = el.dataset.coords.split(',');

          ymaps
            .load(
              'https://api-maps.yandex.ru/2.1/?apikey=407d8034-6968-43ea-be86-af6bcc45592b&lang=ru_RU'
            )
            .then((maps) => {
              contactsMap = new maps.Map(mapId.substring(1), {
                center: coords,
                zoom: 16,
                controls: ['zoomControl'],
              });
              contactsMap.behaviors.disable('scrollZoom');

              myPlacemark = new maps.Placemark(
                contactsMap.getCenter(),
                {
                  hintContent: hintContent,
                  balloonContent: balloonContent,
                },
                {
                  iconLayout: 'default#image',
                  iconImageHref: '/wp-content/uploads/2021/04/favicon.svg',
                  iconImageSize: [37, 62],
                  iconImageOffset: [-18, -62],
                }
              );

              contactsMap.geoObjects.add(myPlacemark);
            })
            .catch((error) => console.log('Failed to load Yandex Maps', error));
        },
      });
      animationObserver.observe();
    }
  }

  // Инициализация карт
  initializeMap('#penza-map', '#penza-map-data');
  initializeMap('#moscow-map', '#moscow-map-data');

  // Квиз
  if (document.querySelector('.quiz')) {
    document.querySelectorAll('.quiz').forEach((quiz) => {
      quiz.querySelectorAll('a[data-quiz-question]').forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();

          let target = e.currentTarget.hash,
            step = Number(document.querySelector(target).dataset.step),
            allSteps = Number(document.querySelector(target).dataset.steps),
            sidebarIcon = e.currentTarget.dataset.sidebarIcon;

          document.querySelector(target).closest('.quiz').querySelector('span.current').innerHTML =
            step;
          document
            .querySelector(target)
            .closest('.quiz')
            .querySelector('span.percent')
            .setAttribute('style', '--width:' + (100 / allSteps) * step + '%');
          e.currentTarget
            .closest('.quiz')
            .querySelectorAll('.quiz-question')
            .forEach((question) => {
              question.classList.remove('is-active');
            });
          document.querySelector(target).classList.add('is-active');

          let currentStep = document.createElement('div');
          currentStep.setAttribute('style', '--icon:url("' + sidebarIcon + '")');
          currentStep.innerHTML = e.currentTarget.querySelector('span').innerHTML;
          quiz.querySelector('.quiz-sidebar').append(currentStep);
          quiz.querySelector('a.quiz-restart').classList.add('is-active');

          let currentQuizData = '';
          quiz.querySelectorAll('.quiz-sidebar div').forEach((answer) => {
            currentQuizData = currentQuizData + answer.innerHTML + ', ';
          });
          quiz.querySelector('input[name="quiz"]').value = currentQuizData;
        });
      });
      quiz.querySelector('a.quiz-restart').addEventListener('click', (e) => {
        e.preventDefault();

        let target = e.currentTarget.hash,
          allSteps = Number(document.querySelector(target).dataset.steps);

        document.querySelector(target).closest('.quiz').querySelector('span.current').innerHTML = 1;
        document
          .querySelector(target)
          .closest('.quiz')
          .querySelector('span.percent')
          .setAttribute('style', '--width:' + (100 / allSteps) * 1 + '%');
        e.currentTarget
          .closest('.quiz')
          .querySelectorAll('.quiz-question')
          .forEach((question) => {
            question.classList.remove('is-active');
          });
        document.querySelector(target).classList.add('is-active');
        e.currentTarget.closest('.quiz').querySelector('.quiz-sidebar').innerHTML = '';
        e.currentTarget.classList.remove('is-active');
        quiz.querySelector('input[name="quiz"]').value = '';
      });
    });
  }

  // Мобильное меню
  if (document.querySelector('button.hamburger')) {
    document.querySelector('.header__wrap__nav').style.display = 'block';
    document.querySelector('button.hamburger').addEventListener('click', (e) => {
      e.currentTarget.classList.toggle('is-active');

      if (e.currentTarget.classList.contains('is-active')) {
        document.querySelector('.header__wrap__nav').classList.add('is-active');
        document.querySelector('.header__wrap__mask').classList.add('is-active');
      } else {
        document.querySelector('.header__wrap__nav').classList.remove('is-active');
        document.querySelector('.header__wrap__mask').classList.remove('is-active');
      }
    });
    document.querySelector('.header__wrap__mask').addEventListener('click', () => {
      document.querySelector('button.hamburger').classList.remove('is-active');
      document.querySelector('.header__wrap__nav').classList.remove('is-active');
      document.querySelector('.header__wrap__mask').classList.remove('is-active');
    });

    /*

    document.querySelectorAll('.header__mobile__wrap__menu a.parent').forEach(el => {
      el.addEventListener('click', e => {
        e.preventDefault();
        e.currentTarget.classList.toggle('is-opened');
        e.target.closest('li').querySelector('ul').classList.toggle('is-opened');
      })
    })
    */
  }

  // Отложенная загрузка
  const observer = lozad();
  observer.observe();

  // Отправка формы
  if (document.querySelector('form.ajax')) {
    document.querySelectorAll('form.ajax').forEach((form) => {
      form.addEventListener('submit', (evt) => {
        evt.preventDefault();
        let yaGoal = form.querySelector('input[name="ya_goal"]').value;
        let mailruGoalID = form.querySelector('input[name="mail_ru_goal_id"]')?.value;
        let phone = form.querySelector('input[name="phone"]').value;

        if (phone.length[17] == '_' || phone.length == 0) {
          document.querySelector('input[name="phone"]').classList.add('is-error');
        } else {
          document.querySelector('#loading').classList.add('is-active');
          if (form.querySelector('input[type="submit"]'))
            form.querySelector('input[type="submit"]').setAttribute('disabled', '');
          if (form.querySelector('button[type="submit"]'))
            form.querySelector('button[type="submit"]').setAttribute('disabled', '');

          let data = new FormData(form);
          leadgets('lead', data, (r) => {
            if (r.status == 1) {
              document.querySelector('#loading').classList.remove('is-active');
              $.fancybox.close();
              if (form.querySelector('input[type="submit"]'))
                form.querySelector('input[type="submit"]').removeAttribute('disabled', '');
              if (form.querySelector('button[type="submit"]'))
                form.querySelector('button[type="submit"]').removeAttribute('disabled', '');

              if (form.querySelector('input[name="name"]')) {
                form.querySelector('input[name="name"]').value = '';
                form.querySelector('input[name="name"]').classList.remove('is-filled');
              }
              if (form.querySelector('input[name="phone"]')) {
                form.querySelector('input[name="phone"]').value = '';
                form.querySelector('input[name="phone"]').classList.remove('is-filled');
              }
              if (form.querySelector('input[name="email"]')) {
                form.querySelector('input[name="email"]').value = '';
                form.querySelector('input[name="email"]').classList.remove('is-filled');
              }
              if (form.querySelector('input[name="company"]')) {
                form.querySelector('input[name="company"]').value = '';
                form.querySelector('input[name="company"]').classList.remove('is-filled');
              }
              if (form.querySelector('textarea')) {
                form.querySelector('textarea').value = '';
                form.querySelector('textarea').classList.remove('is-filled');
              }
              if (form.querySelector('input[type="checkbox"]')) {
                form.querySelectorAll('input[type="checkbox"]').forEach((el) => {
                  if (el.name != 'agreement') el.checked = false;
                });
              }

              setTimeout(function () {
                $.fancybox.open({
                  src: '#thanks',
                  type: 'inline',
                  opts: {
                    beforeLoad: (instance, current) => {
                      document.querySelector('.header').style.marginRight = '8px';
                    },
                    afterClose: () => {
                      document.querySelector('.header').style = '';
                    },
                  },
                });
              }, 150);
              setTimeout(function () {
                $.fancybox.close();
              }, 5000);

              if (typeof ym == 'function') {
                ym('XXXXXXXX', 'reachGoal', yaGoal);
                console.log('Цель достигнута: ' + yaGoal);
              }
              if (mailruGoalID) {
                var _tmr = window._tmr || (window._tmr = []);
                _tmr.push({ type: 'reachGoal', id: parseInt(mailruGoalID), goal: 'Lead' });
                console.log('Цель достигнута: ' + mailruGoalID);
              }
              if (typeof gtag == 'function') {
                gtag('event', 'form_lead', {
                  event_category: 'lead',
                  event_action: 'zayavka',
                });
              }
            } else {
              alertify.notify(
                'Ошибка! Некорректный формат номера, минимум 11 символов. Пример: 79876543210',
                5
              );

              document.querySelector('#loading').classList.remove('is-active');
              $.fancybox.close();
              form.querySelector('input[type="submit"]').removeAttribute('disabled', '');
            }
          });
        }
      });
    });
  }

  // Переход к блоку
  if (document.querySelector('.move-to')) {
    document.querySelectorAll('.move-to').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        let linkId = e.currentTarget.hash,
          blockId = e.currentTarget.dataset.move,
          offset = 110;

        if (window.innerWidth < 416) offset = 80;
        if (linkId && linkId[0] !== '/' && linkId !== '#top') {
          let linkTop = $(linkId).offset().top - offset;
          window.scrollTo({
            top: linkTop,
            behavior: 'smooth',
          });
        } else if (blockId) {
          let blockTop = $(blockId).offset().top - offset;
          window.scrollTo({
            top: blockTop,
            behavior: 'smooth',
          });
        }
        if (linkId == '#top') {
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      });
    });
  }

  // Переход на страницу
  if (document.querySelector('*[data-link]')) {
    document.querySelectorAll('*[data-link]').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        let url = e.currentTarget.dataset.link;

        document.location.href = url;
      });
    });
  }

  // Попап
  if (document.querySelector('.popup-link')) {
    document.querySelectorAll('.popup-link').forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        let popup = e.currentTarget.hash || e.target.dataset.href,
          title = e.currentTarget.dataset.title,
          content = e.currentTarget.dataset.content,
          footer = e.currentTarget.dataset.footer,
          submit = e.currentTarget.dataset.submit,
          yagoal = e.currentTarget.dataset.yagoal,
          mailruGoalID = e.currentTarget.dataset.mailruGoalId,
          email = e.currentTarget.dataset.email;

        if (popup) {
          $.fancybox.open({
            src: popup,
            type: 'inline',
            opts: {
              beforeLoad: function (instance, current) {
                if (title) $(current.src + ' .modal__title p').html(title);
                if (content) $(current.src + ' .modal__content').html(content);
                if (footer) $(current.src + ' .modal__footer p').html(footer);
                if (submit) $(current.src + ' input[type="submit"]').val(submit);
                if (yagoal) $(current.src + ' input[name="ya_goal"]').val(yagoal);
                if (mailruGoalID)
                  $(current.src + ' input[name="mail_ru_goal_id"]').val(mailruGoalID);
                if (email) $(current.src + ' input[name="email_title"]').val(email);
              },
            },
          });
        }
      });
    });
  }

  // Появление scrollToTop
  if (document.querySelector('#scroll-to-top')) {
    window.addEventListener('scroll', (e) => {
      let button = document.querySelector('#scroll-to-top');
      if (e.currentTarget.pageYOffset > 1000) {
        button.classList.add('is-active');
      } else {
        button.classList.remove('is-active');
      }
    });
  }

  // Слайдеры
  if (document.querySelector('.swiper-container.main-cases')) {
    let mainCasesSwiper = new Swiper('.swiper-container.main-cases', {
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
        991: {
          slidesPerView: 2,
        },
        1261: {
          slidesPerView: 3,
        },
      },
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.main-cases',
      },
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.main-directions')) {
    let mainCasesSwiper = new Swiper('.swiper-container.main-directions', {
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        515: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.main-directions',
      },
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.main-clients')) {
    let mainClientsSwiper = new Swiper('.swiper-container.main-clients', {
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        515: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 4,
        },
      },
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.main-clients',
      },
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.projects')) {
    let counter = 0;
    document.querySelectorAll('.swiper-container.projects').forEach((el) => {
      el.classList.add('projects-' + counter);
      el.closest('.tabs__content__slider')
        .querySelector('.swiper-pagination.projects')
        .classList.add('projects-' + counter);

      let projectSwiper = new Swiper('.swiper-container.projects-' + counter, {
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          515: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        },
        loop: false,
        lazy: true,
        observer: true,
        observeParents: true,
        preloadImages: true,
        pagination: {
          clickable: true,
          el: '.swiper-pagination.projects-' + counter,
          // dynamicBullets: true,
          // dynamicMainBullets: 3,
        },
        spaceBetween: 30,
      });

      counter++;
    });
  }
  if (document.querySelector('.swiper-container.context-cases')) {
    let counter = 0;
    document.querySelectorAll('.swiper-container.context-cases').forEach((el) => {
      el.classList.add('context-cases-' + counter);
      el.closest('.tabs__content__wrap__item__slider')
        .querySelector('.swiper-pagination.context-cases')
        .classList.add('context-cases-' + counter);

      let caseSwiper = new Swiper('.swiper-container.context-cases-' + counter, {
        loop: false,
        lazy: true,
        observer: true,
        observeParents: true,
        preloadImages: true,
        pagination: {
          clickable: true,
          el: '.swiper-pagination.context-cases-' + counter,
          dynamicBullets: true,
          dynamicMainBullets: 3,
        },
        slidesPerView: 1,
        spaceBetween: 30,
      });

      counter++;
    });
  }
  if (document.querySelector('.swiper-container.context-examples')) {
    let counter = 0;
    document.querySelectorAll('.swiper-container.context-examples').forEach((el) => {
      el.classList.add('context-examples-' + counter);
      el.closest('.context__examples__list__item__slider')
        .querySelector('.swiper-pagination.context-examples')
        .classList.add('context-examples-' + counter);

      let caseSwiper = new Swiper('.swiper-container.context-examples-' + counter, {
        loop: false,
        lazy: true,
        observer: true,
        observeParents: true,
        preloadImages: true,
        pagination: {
          clickable: true,
          el: '.swiper-pagination.context-examples-' + counter,
          dynamicBullets: true,
          dynamicMainBullets: 3,
        },
        slidesPerView: 1,
        spaceBetween: 30,
      });

      counter++;
    });
  }
  if (document.querySelector('.swiper-container.project-gallery')) {
    let testimonialSwiper = new Swiper('.swiper-container.project-gallery', {
      autoplay: {
        delay: 3000,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        575: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
        991: {
          slidesPerView: 4,
        },
        1200: {
          slidesPerView: 5,
        },
      },
      centeredSlides: true,
      lazy: true,
      loop: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.project-gallery',
      },
      preloadImages: true,
      speed: 750,
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.digital-parts')) {
    let counter = 0;
    document.querySelectorAll('.swiper-container.digital-parts').forEach((el) => {
      el.classList.add('digital-parts-' + counter);
      el.closest('.digital__steps__parts__item__slider')
        .querySelector('.swiper-pagination.digital-parts')
        .classList.add('digital-parts-' + counter);

      let digitalSwiper = new Swiper('.swiper-container.digital-parts-' + counter, {
        autoHeight: true,
        loop: false,
        lazy: true,
        observer: true,
        observeParents: true,
        preloadImages: true,
        pagination: {
          clickable: true,
          el: '.swiper-pagination.digital-parts-' + counter,
          renderBullet: (index, className) => {
            return (
              '<span class="' +
              className +
              '">' +
              (index + 1) +
              ' <span class="text">этап</span></span>'
            );
          },
        },
        slidesPerView: 1,
        spaceBetween: 30,
      });

      counter++;
    });
  }
  if (document.querySelector('.swiper-container.audit-types')) {
    let auditSwiper = new Swiper('.swiper-container.audit-types', {
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.audit-types',
      },
      slidesPerView: 1,
      spaceBetween: 30,
      on: {
        init: () => {
          document.querySelectorAll('.audit__types__slider__nav .button').forEach((btn) => {
            btn.addEventListener('click', (evt) => {
              evt.preventDefault();
              evt.currentTarget
                .closest('.audit__types__slider__nav')
                .querySelectorAll('.button')
                .forEach((el) => el.classList.remove('is-active'));
              evt.currentTarget.classList.add('is-active');

              let index = evt.currentTarget.dataset.index;
              auditSwiper.slideTo(index);
            });
          });
        },
      },
    });
  }
  if (document.querySelector('.swiper-container.service-cases')) {
    let mainCasesSwiper = new Swiper('.swiper-container.service-cases', {
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
        991: {
          slidesPerView: 2,
        },
        1261: {
          slidesPerView: 3,
        },
      },
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.service-cases',
      },
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.linear-quiz')) {
    let linearCaseSwiper = new Swiper('.swiper-container.linear-quiz', {
        allowTouchMove: false,
        autoHeight: true,
        loop: false,
        navigation: {
          nextEl: '.swiper-button-next.linear-quiz',
          prevEl: '.swiper-button-prev.linear-quiz',
        },
        observer: true,
        observeParents: true,
        slidesPerView: 1,
        spaceBetween: 30,
      }),
      progressSpan = document.querySelector(
        '.promotion-residential__quiz__wrap__content__percent span.percent'
      ),
      stepItems = document.querySelectorAll(
        '.promotion-residential__quiz__wrap__sidebar__list__item'
      ),
      submitButton = document.querySelector(
        '.promotion-residential__quiz__wrap__sidebar__nav button[type="submit"]'
      );

    linearCaseSwiper.on('slideChange', () => {
      progressSpan.setAttribute(
        'style',
        '--width: ' + (linearCaseSwiper.activeIndex + 1) * 25 + '%'
      );
    });
    linearCaseSwiper.on('slideNextTransitionStart', () => {
      stepItems[linearCaseSwiper.activeIndex].classList.add('active');

      if (linearCaseSwiper.isEnd) {
        submitButton.style = '';
        linearCaseSwiper.navigation.nextEl.style.display = 'none';
      }
    });
    linearCaseSwiper.on('slidePrevTransitionStart', () => {
      submitButton.style.display = 'none';
      linearCaseSwiper.navigation.nextEl.style = '';
      stepItems[linearCaseSwiper.activeIndex + 1].classList.remove('active');
    });
  }
  if (document.querySelector('.swiper-container.branding-cases')) {
    let projectSwiper = new Swiper('.swiper-container.branding-cases', {
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        515: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.branding-cases',
      },
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.video-adv-advatages')) {
    let advantagesSwiper = new Swiper('.swiper-container.video-adv-advatages', {
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.video-adv-advatages',
      },
      slidesPerView: 1,
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.video-adv-yandex-benefits')) {
    let benefitsSwiper = new Swiper('.swiper-container.video-adv-yandex-benefits', {
      autoHeight: true,
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.video-adv-yandex-benefits',
      },
      slidesPerView: 1,
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.video-adv-yandex-advert-location')) {
    let locationSwiper = new Swiper('.swiper-container.video-adv-yandex-advert-location', {
      autoHeight: true,
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.video-adv-yandex-advert-location',
      },
      slidesPerView: 1,
      spaceBetween: 30,
    });
  }
  if (document.querySelector('.swiper-container.video-adv-yandex-covering-adv')) {
    let coveringAdvertSwiper = new Swiper('.swiper-container.video-adv-yandex-covering-adv', {
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.video-adv-yandex-covering-adv',
      },
      slidesPerView: 1,
      spaceBetween: 30,
    });
  }

  if (document.querySelector('.swiper-container.share-experience__wrapper__slider')) {
    let shaerExperience = new Swiper('.swiper-container.share-experience__wrapper__slider', {
      breakpoints: {
        768: {
          slidesPerView: 1,
        },
        991: {
          slidesPerView: 2,
        },
        1261: {
          slidesPerView: 3,
        },
      },
      slidesPerGroup: 1,
      loop: false,
      lazy: true,
      observer: true,
      observeParents: true,
      preloadImages: true,
      pagination: {
        clickable: true,
        el: '.swiper-pagination.share-experience__pagination',
      },
      spaceBetween: 30,

      navigation: {
        nextEl: '.swiper-button-next.share-experience__wrapper__button',
        prevEl: '.swiper-button-prev.share-experience__wrapper__button',
      },
    });
  }

  if (
    document.querySelector(
      '.swiper-container.yandex-contextual-adv__types-of-ads-and-RK-parameters__slider'
    )
  ) {
    let typesOfAdsAndRKparametersSwiper = new Swiper(
      '.swiper-container.yandex-contextual-adv__types-of-ads-and-RK-parameters__slider',
      {
        loop: false,
        preloadImages: true,
        spaceBetween: 20,
        breakpoints: {
          0: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1.5,
          },
          768: {
            slidesPerView: 1.8,
          },
        },
      }
    );
  }

  if (
    document.querySelector('.swiper-container.about-company__company-history__slider__container')
  ) {
    const companyHistorySwiper = new Swiper(
      '.swiper-container.about-company__company-history__slider__container',
      {
        loop: false,
        slidesPerView: 4,
        speed: 300, // by default
        spaceBetween: 60,
        autoHeight: true,
        observer: true,
        observeSlideChildren: true,
        breakpoints: {
          0: {
            slidesPerView: 3,
            slidesPerGroup: 2,
          },
          991: {
            slidesPerView: 4,
          },
        },
        pagination: {
          clickable: true,
          el: '.swiper-pagination.about-company__company-history__slider__pagination',
          dynamicBullets: true,
          dynamicMainBullets: 3,
        },
        navigation: {
          nextEl: '.swiper-button-next.about-company__company-history__slider__button',
          prevEl: '.swiper-button-prev.about-company__company-history__slider__button',
        },
        on: {
          init: function () {
            centerSwiperBreakpoints(this.slides);
            setCorretionVerticalPaddings(this.slides, this.params.slidesPerView, this.activeIndex);
            centerLineRelativeSwiperBreakpoints(
              this.slides,
              this.params.slidesPerView,
              this.activeIndex
            );
          },
          slideChange: function () {
            setCorretionVerticalPaddings(this.slides, this.params.slidesPerView, this.activeIndex);
            centerLineRelativeSwiperBreakpoints(
              this.slides,
              this.params.slidesPerView,
              this.activeIndex
            );
          },
        },
      }
    );
    addEventListener('resize', (e) => {
      centerSwiperBreakpoints(companyHistorySwiper.slides);
      centerLineRelativeSwiperBreakpoints(
        companyHistorySwiper.slides,
        companyHistorySwiper.params.slidesPerView,
        companyHistorySwiper.activeIndex
      );
    });
  }

  if (document.querySelector('.swiper-container.testimonials__slider__container')) {
    let testimonialsSwiper = new Swiper('.swiper-container.testimonials__slider__container', {
      loop: false,
      spaceBetween: 20,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 60,
        },
        500: {
          slidesPerView: 2,
          centeredSlides: false,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
        },
        1260: {
          slidesPerView: 4,
        },
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination.testimonials__slider__pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next.testimonials__slider__button',
        prevEl: '.swiper-button-prev.testimonials__slider__button',
      },
    });
  }

  if (document.querySelector('.swiper-container.industry-page__cert__container')) {
    let industryCertSwiper = new Swiper('.swiper-container.industry-page__cert__container', {
      loop: false,
      spaceBetween: 20,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 60,
        },
        500: {
          slidesPerView: 2,
          centeredSlides: false,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 3,
        },
        1260: {
          slidesPerView: 4,
        },
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination.industry-page__cert__pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next.industry-page__cert__button',
        prevEl: '.swiper-button-prev.industry-page__cert__button',
      },
    });
  }

  if (document.querySelector('.swiper-container.blog__readmore__slider__container')) {
    let blogReadmoreSwiper = new Swiper('.swiper-container.blog__readmore__slider__container', {
      loop: false,
      spaceBetween: 20,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        0: {
          slidesPerView: 1,
          centeredSlides: true,
          spaceBetween: 60,
        },
        500: {
          slidesPerView: 1,
          centeredSlides: false,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
        },
        1260: {
          slidesPerView: 3,
        },
      },
      pagination: {
        clickable: true,
        el: '.swiper-pagination.blog__readmore__pagination',
      },
      navigation: {
        nextEl: '.swiper-button-next.blog__readmore__swiper__btn',
        prevEl: '.swiper-button-prev.blog__readmore__swiper__btn',
      },
    });
  }

  // Шаги
  if (document.querySelector('.steps')) {
    document.querySelectorAll('.steps').forEach((steps) => {
      steps.querySelectorAll('.step-button').forEach((step) => {
        step.addEventListener('click', (e) => {
          e.currentTarget
            .closest('.steps')
            .querySelectorAll('.step-button')
            .forEach((button) => {
              button.classList.remove('is-active');
            });
          e.currentTarget.classList.add('is-active');
          let target = e.currentTarget.dataset.target;
          e.currentTarget
            .closest('.steps')
            .querySelectorAll('.step-content')
            .forEach((content) => {
              content.classList.remove('is-active');
              if (content.dataset.content == target) content.classList.add('is-active');
            });
        });
      });
    });
  }

  // Phone mask / validate
  if (document.querySelector("input[type='tel']")) {
    let phoneInputs = document.querySelectorAll("input[type='tel']");
    let getInputNumbersValue = function (input) {
      return input.value.replace(/\D/g, '');
    };
    let onPhonePaste = function (e) {
      let input = e.target,
        inputNumbersValue = getInputNumbersValue(input);
      let pasted = e.clipboardData || window.clipboardData;
      if (pasted) {
        let pastedText = pasted.getData('Text');
        if (/\D/g.test(pastedText)) {
          input.value = inputNumbersValue;
          return;
        }
      }
    };
    let onPhoneInput = function (e) {
      let input = e.target,
        inputNumbersValue = getInputNumbersValue(input),
        selectionStart = input.selectionStart,
        formattedInputValue = '';
      if (!inputNumbersValue) {
        return (input.value = '');
      }
      if (input.value.length !== selectionStart) {
        if (e.data && /\D/g.test(e.data)) {
          input.value = inputNumbersValue;
        }
        return;
      }
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
        if (inputNumbersValue[0] === '9') inputNumbersValue = '7' + inputNumbersValue;
        let firstSymbols =
          inputNumbersValue[0] === '8' || inputNumbersValue[0] === '7' ? '8' : '+7';
        formattedInputValue = input.value = firstSymbols + ' ';
        if (inputNumbersValue.length > 1) {
          formattedInputValue += '(' + inputNumbersValue.substring(1, 4);
          this.classList.add('is-invalid');
          document
            .querySelectorAll('input[type="submit"]')
            .forEach((item) => item.classList.add('disabled'));
        }
        if (inputNumbersValue.length >= 5) {
          formattedInputValue += ') ' + inputNumbersValue.substring(4, 7);
        }
        if (inputNumbersValue.length >= 8) {
          formattedInputValue += '-' + inputNumbersValue.substring(7, 9);
        }
        if (inputNumbersValue.length >= 10) {
          formattedInputValue += '-' + inputNumbersValue.substring(9, 11);
        }
        if (inputNumbersValue.length >= 11) {
          this.classList.remove('is-invalid');
          this.classList.add('is-valid');
          document
            .querySelectorAll('input[type="submit"]')
            .forEach((item) => item.classList.remove('disabled'));
        }
      } else {
        formattedInputValue = '+' + inputNumbersValue.substring(0, 16);
      }
      input.value = formattedInputValue;
    };
    let onPhoneKeyDown = function (e) {
      let inputValue = e.target.value.replace(/\D/g, '');
      if (e.keyCode == 8 && inputValue.length == 1) {
        e.target.value = '';
      }
    };

    document.querySelectorAll('input[type="submit"]').forEach(() => {
      phoneInputs.forEach((phoneInput) => {
        phoneInput.addEventListener('keydown', onPhoneKeyDown);
        phoneInput.addEventListener('input', onPhoneInput, false);
        phoneInput.addEventListener('paste', onPhonePaste, false);
      });
    });
  }
});

// Centering elements regarding X axis
function centerSwiperBreakpoints(slides) {
  for (let i = 1; i < slides.length; i++) {
    const heightPrevSlide =
      parseFloat(getComputedStyle(slides[i - 1]).height) -
      parseFloat(getComputedStyle(slides[i - 1]).paddingBottom);
    const heightCurrentSlide =
      parseFloat(getComputedStyle(slides[i]).height) -
      parseFloat(getComputedStyle(slides[i]).paddingBottom);
    // there is no any paddingBottom until we call setCorretionVerticalPaddings function.

    const marginTopPrevSlide = parseFloat(getComputedStyle(slides[i - 1]).marginTop);
    //this value is used for top slides(odd ones) to correctly calculate the previous bottom slide(even one)

    if (i % 2 === 0) {
      // for top slides(odd ones)
      slides[i].style.marginTop = `${marginTopPrevSlide - heightCurrentSlide}px`;
    } else {
      // for bottom slides(even ones)
      slides[i].style.marginTop = `${heightPrevSlide + marginTopPrevSlide}px`;
      //here we use marginTopPrevSlide, because some top slides can go beyoned swiper container height, so we also need to count the value it goes beyoned.
    }
  }
}

// Providing correct work for "autohHeight" property of the slider
function setCorretionVerticalPaddings(slides, amountOfVisibleSlides, currentStartSlideIndex) {
  const container = document.querySelector(
    '.about-company__company-history__slider__auto-height-wrapper'
  );
  let maxMinusMarginTopOfTopSlides = 0;
  let maxHeightOfDownSlides = 0;

  for (let i = currentStartSlideIndex, j = 0; j < amountOfVisibleSlides; i++, j++) {
    if (i === 0) continue;

    const heightCurrentSlide =
      parseFloat(getComputedStyle(slides[i]).height) -
      parseFloat(getComputedStyle(slides[i]).paddingBottom);

    if (i % 2 === 0) {
      // for top slides(odd ones)
      const marginTopPrevSlide = parseFloat(getComputedStyle(slides[i - 1]).marginTop);
      const marginTopCurrentSlide = marginTopPrevSlide - heightCurrentSlide;
      if (marginTopCurrentSlide < 0) maxMinusMarginTopOfTopSlides = marginTopCurrentSlide;
    } else {
      // for bottom slides(even ones)
      if (maxHeightOfDownSlides < heightCurrentSlide) maxHeightOfDownSlides = heightCurrentSlide;
    }
  }

  for (let i = currentStartSlideIndex, j = 0; j < amountOfVisibleSlides; i++, j++) {
    if (i % 2 !== 0) continue;

    // for top slides(odd ones)
    slides[i].style.paddingBottom = `${
      maxHeightOfDownSlides - Math.abs(maxMinusMarginTopOfTopSlides)
    }px`;
  }

  container.style.paddingTop = `${Math.abs(maxMinusMarginTopOfTopSlides)}px`;
}

// Line centering regarding breakpoints
function centerLineRelativeSwiperBreakpoints(
  slides,
  amountOfVisibleSlides,
  currentStartSlideIndex
) {
  const title = document.querySelector('.about-company__company-history .block__title h2');
  const titleHeightWithMargin =
    parseFloat(getComputedStyle(title).height) + parseFloat(getComputedStyle(title).marginBottom);
  const line = document.querySelector('.about-company__company-history__line');

  let maxMinusMarginTop = 0;
  let maxHeightTopPart = 0;

  for (let i = currentStartSlideIndex, j = 0; j < amountOfVisibleSlides; i++, j++) {
    if (i % 2 !== 0 || i === 0) continue;
    // for top slides(odd ones)
    const heightPrevSlide =
      parseFloat(getComputedStyle(slides[i - 1]).height) -
      parseFloat(getComputedStyle(slides[i - 1]).paddingBottom);
    const heightCurrentSlide =
      parseFloat(getComputedStyle(slides[i]).height) -
      parseFloat(getComputedStyle(slides[i]).paddingBottom);

    const marginTopPrevSlide = parseFloat(getComputedStyle(slides[i - 1]).marginTop);
    const marginTopCurrentSlide = parseFloat(getComputedStyle(slides[i]).marginTop);

    const difference = marginTopPrevSlide - heightCurrentSlide;

    if (difference < 0) maxMinusMarginTop = difference;
    if (heightCurrentSlide + difference > heightPrevSlide + marginTopCurrentSlide) {
      maxHeightTopPart = heightCurrentSlide + difference;
    } else {
      maxHeightTopPart = heightPrevSlide + marginTopCurrentSlide;
    }
  }

  // here we're are relating on maxHeightOfelement, so we don't need paddingTop;
  line.style.top = `${titleHeightWithMargin + Math.abs(maxMinusMarginTop) + maxHeightTopPart}px`;
}

// select ssl form
if (document.querySelector('.ssl-order')) {
  const selectSingle = document.querySelector('.select');
  const selectSingle_title = selectSingle.querySelector('.select__title');
  const selectSingle_labels = selectSingle.querySelectorAll('.select__label');

  selectSingle_title.addEventListener('click', () => {
    if ('active' === selectSingle.getAttribute('data-state')) {
      selectSingle.setAttribute('data-state', '');
    } else {
      selectSingle.setAttribute('data-state', 'active');
    }
  });

  for (let i = 0; i < selectSingle_labels.length; i++) {
    selectSingle_labels[i].addEventListener('click', (evt) => {
      selectSingle_title.textContent = evt.target.textContent;
      selectSingle.setAttribute('data-state', '');
    });
  }

  const elementsInput = document.querySelectorAll('.select__input');
  elementsInput.forEach(function (element) {
    element.addEventListener('change', (evt) => {
      let theme = 'Заказать SSL сертификат - тариф ' + evt.target.value;
      document.querySelector('input[name="email_title"]').value = theme;
    });
  });
}

// Контакты, табы. доп контакты
document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('.main__contacts')) {
    const tab1 = document.querySelector('[href="#tab-1"]');
    const tab0 = document.querySelector('[href="#tab-0"]');
    const additionalSocialMoscow = document.querySelector('#additional-social-moscow');
    const additionalSocialPenza = document.querySelector('#additional-social-penza');

    if (tab1 && tab0 && additionalSocialMoscow && additionalSocialPenza) {
      function showMoscow() {
        additionalSocialPenza.classList.remove('active');
        setTimeout(() => {
          additionalSocialPenza.style.display = 'none';
          additionalSocialMoscow.style.display = 'flex';
          setTimeout(() => {
            additionalSocialMoscow.classList.add('active');
          }, 5);
        }, 100);
        tab1.setAttribute('aria-selected', 'true');
        tab0.removeAttribute('aria-selected');
      }

      function showPenza() {
        additionalSocialMoscow.classList.remove('active');
        setTimeout(() => {
          additionalSocialMoscow.style.display = 'none';
          additionalSocialPenza.style.display = 'flex';
          setTimeout(() => {
            additionalSocialPenza.classList.add('active');
          }, 5);
        }, 100);
        tab0.setAttribute('aria-selected', 'true');
        tab1.removeAttribute('aria-selected');
      }

      tab1.addEventListener('click', showMoscow);
      tab0.addEventListener('click', showPenza);

      if (tab1.hasAttribute('data-tabby-default')) {
        showMoscow();
      } else if (tab0.hasAttribute('data-tabby-default')) {
        showPenza();
      }
    }
  }
});

// Селект для страницы кейсы
document.addEventListener('DOMContentLoaded', () => {
  const element = document.querySelector('.cases__activity__form__inner__select');
  const choices = new Choices(element, {
    searchEnabled: false,
    itemSelectText: '',
  });

  const arrow = document.createElement('div');
  arrow.classList.add('arrow');
  arrow.innerHTML =
    '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18 9L12.7071 14.2929C12.3166 14.6834 11.6834 14.6834 11.2929 14.2929L6 9" stroke="#141B38" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>';
  document.querySelector('.choices').appendChild(arrow);

  const button = document.getElementById('cases__activity__button');

  // Функция для обновления атрибута data-email
  const updateButtonDataEmail = () => {
    const selectedElement = document.querySelector('.choices__item.is-selected');
    if (selectedElement) {
      const selectedText = selectedElement.textContent.trim();
      button.setAttribute(
        'data-email',
        `Обратный звонок, страница кейсы, сфера деятельность - ${selectedText}`
      );
    }
  };

  // Обновляем data-email при изменении выбора
  element.addEventListener('change', updateButtonDataEmail);
});

// Свайпер, общая страница блогов
document.addEventListener('DOMContentLoaded', function () {
  var swiper = new Swiper('.blog-page__hero__wrapper__swiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.blog-page__hero__wrapper__swiper__next',
      prevEl: '.blog-page__hero__wrapper__swiper__prev',
    },
    pagination: {
      el: '.blog-page__hero__wrapper__swiper__pagination',
      clickable: true,
      dynamicBullets: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  // Логика счетчика просмотров
  document.addEventListener('click', function (event) {
    var slide = event.target.closest('.blog-page__hero__wrapper__swiper__slide');
    if (slide) {
      var postId = slide.getAttribute('data-post-id');
      if (postId) {
        incrementPostViews(postId);
      }
    }
  });

  function incrementPostViews(postId) {
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/wp-admin/admin-ajax.php', true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 400) {
        console.log('Views updated: ' + xhr.responseText);
      } else {
        console.error('Error updating views');
      }
    };
    xhr.onerror = function () {
      console.error('Request failed');
    };
    xhr.send('action=increment_post_views&post_id=' + postId);
  }
});

// Самоликвидация сервис воркера
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      console.debug('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.debug('Service Worker registration failed:', error);
    });
}

// Навигация хедер
document.addEventListener('DOMContentLoaded', function () {
  var menuContainer = document.querySelector('.menu-glavnoe-menju-container');
  var menuItems = document.querySelectorAll('.menu-item-has-children');

  menuItems.forEach(function (menuItem) {
    menuItem.addEventListener('mouseenter', function () {
      menuItems.forEach(function (item) {
        if (item !== menuItem) {
          item.classList.remove('open');
        }
      });
      menuItem.classList.add('open');
    });
  });

  document.addEventListener('click', function (event) {
    if (!menuContainer.contains(event.target)) {
      menuItems.forEach(function (menuItem) {
        menuItem.classList.remove('open');
      });
    }
  });

  window.addEventListener('scroll', function () {
    menuItems.forEach(function (menuItem) {
      menuItem.classList.remove('open');
    });
  });
});

// Свайпер кейсы
document.addEventListener('DOMContentLoaded', function () {
  var swiper = new Swiper('.cases__list__tabs__swiper', {
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  });
});

// Свайпер блог, введение
document.addEventListener('DOMContentLoaded', function () {
  let swiper = new Swiper('.kademablog__wrapper__introduction__slider', {
    loop: false, // Отключить циклическую прокрутку
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.kademablog__wrapper__introduction__slider--next',
      prevEl: '.kademablog__wrapper__introduction__slider--prev',
    },
  });
});

// Аккордеон блог
document.addEventListener('DOMContentLoaded', function () {
  var accordionButtons = document.querySelectorAll('.accordion-button');

  accordionButtons.forEach(function (button) {
    var content = button
      .closest('.kademablog__wrapper__nav')
      .querySelector('.kademablog__wrapper__nav__content');

    // Set initial state based on aria-expanded attribute
    if (button.getAttribute('aria-expanded') === 'true') {
      content.style.maxHeight = content.scrollHeight + 'px';
    }

    button.addEventListener('click', function () {
      var expanded = this.getAttribute('aria-expanded') === 'true' || false;
      this.setAttribute('aria-expanded', !expanded);

      if (!expanded) {
        content.style.maxHeight = content.scrollHeight + 'px';
        content.classList.add('show');
      } else {
        content.style.maxHeight = 0;
        content.classList.remove('show');
      }
    });
  });
});

// Свайперы блог, текстовые блоки
document.addEventListener('DOMContentLoaded', function () {
  const sliders = [
    {
      selector: '.kademablog__wrapper__text__img__slider1',
      pagination: '.kademablog__wrapper__text__img__slider1--pagination',
      next: '.kademablog__wrapper__text__img__slider1--next',
      prev: '.kademablog__wrapper__text__img__slider1--prev',
    },
    {
      selector: '.kademablog__wrapper__text__img__slider2',
      pagination: '.kademablog__wrapper__text__img__slider2--pagination',
      next: '.kademablog__wrapper__text__img__slider2--next',
      prev: '.kademablog__wrapper__text__img__slider2--prev',
    },
    {
      selector: '.kademablog__wrapper__text__img__slider3',
      pagination: '.kademablog__wrapper__text__img__slider3--pagination',
      next: '.kademablog__wrapper__text__img__slider3--next',
      prev: '.kademablog__wrapper__text__img__slider3--prev',
    },
    {
      selector: '.kademablog__wrapper__text__img__slider4',
      pagination: '.kademablog__wrapper__text__img__slider4--pagination',
      next: '.kademablog__wrapper__text__img__slider4--next',
      prev: '.kademablog__wrapper__text__img__slider4--prev',
    },
    {
      selector: '.kademablog__wrapper__text__img__slider5',
      pagination: '.kademablog__wrapper__text__img__slider5--pagination',
      next: '.kademablog__wrapper__text__img__slider5--next',
      prev: '.kademablog__wrapper__text__img__slider5--prev',
    },
  ];

  sliders.forEach((slider) => {
    new Swiper(slider.selector, {
      loop: false, // Отключить циклическую прокрутку
      pagination: {
        el: slider.pagination,
        clickable: true,
      },
      navigation: {
        nextEl: slider.next,
        prevEl: slider.prev,
      },
    });
  });
});

// Свайпер блог, сегментация ЦА
document.addEventListener('DOMContentLoaded', function () {
  let swiper = new Swiper('.kademablog__wrapper__segmentation__swiper', {
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.kademablog__wrapper__segmentation__swiper--next',
      prevEl: '.kademablog__wrapper__segmentation__swiper--prev',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        slidesPerGroup: 1,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3,
        slidesPerGroup: 1,
        spaceBetween: 30,
      },
    },
  });
});

// Рейтинг для блога
document.addEventListener('DOMContentLoaded', function () {
  const starRatingElement = document.querySelector('.star-rating');
  if (!starRatingElement) {
    return;
  }

  const stars = document.querySelectorAll('.star-rating .star');
  const currentRating = parseInt(starRatingElement.getAttribute('data-current-rating'), 10);
  const postId = starRatingElement.getAttribute('data-post-id');
  const hasVoted = localStorage.getItem(`hasVoted_${postId}`);
  setRating(currentRating);
  if (!hasVoted) {
    stars.forEach((star) => {
      star.addEventListener('mouseover', function () {
        const rating = parseInt(this.getAttribute('data-value'), 10);
        highlightStars(rating);
      });
      star.addEventListener('mouseout', function () {
        setRating(currentRating);
      });
      star.addEventListener('click', function () {
        const rating = parseInt(this.getAttribute('data-value'), 10);
        setRating(rating);
        saveRating(rating, postId);
      });
    });
  } else {
    stars.forEach((star) => {
      star.style.cursor = 'default';
    });
  }

  function setRating(rating) {
    stars.forEach((star) => {
      const starValue = parseInt(star.getAttribute('data-value'), 10);
      if (starValue <= rating) {
        star.classList.add('active');
        star.classList.remove('inactive');
      } else {
        star.classList.remove('active');
        star.classList.add('inactive');
      }
    });
  }

  function highlightStars(rating) {
    stars.forEach((star) => {
      const starValue = parseInt(star.getAttribute('data-value'), 10);
      if (starValue <= rating) {
        star.classList.add('active');
        star.classList.remove('inactive');
      } else {
        star.classList.remove('active');
        star.classList.add('inactive');
      }
    });
  }

  function saveRating(rating, postId) {
    fetch('/wp-admin/admin-ajax.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `action=save_rating&post_id=${postId}&rating=${rating}`,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          console.log('Rating saved successfully');
          // Обновляем отображение среднего рейтинга
          const newAverageRating = data.new_average_rating;
          setRating(newAverageRating);
          // Сохраняем информацию о голосовании в localStorage
          localStorage.setItem(`hasVoted_${postId}`, true);
          // Отключаем возможность голосования
          stars.forEach((star) => {
            star.style.cursor = 'default';
          });
        } else {
          console.error('Failed to save rating');
        }
      });
  }
});

document.addEventListener('DOMContentLoaded', function () {
  const starRating = document.querySelector('.star-rating');
  if (!starRating) {
    return;
  }

  const stars = document.querySelectorAll('.star-rating .star');
  const currentRating = parseInt(starRating.getAttribute('data-current-rating'));

  function setStarsActive(upTo) {
    stars.forEach((star, index) => {
      if (index < upTo) {
        star.classList.add('active');
      } else {
        star.classList.remove('active');
      }
    });
  }

  function resetStars() {
    setStarsActive(currentRating);
  }

  stars.forEach((star) => {
    star.addEventListener('mouseover', function () {
      const value = parseInt(star.getAttribute('data-value'));
      setStarsActive(value);
    });
    star.addEventListener('mouseout', function () {
      resetStars();
    });
    star.addEventListener('click', function () {
      const value = parseInt(star.getAttribute('data-value'));
      starRating.setAttribute('data-current-rating', value);
      resetStars();
    });
  });

  resetStars();
});

// бургер меню
document.addEventListener('DOMContentLoaded', function () {
  const burgerMenuButton = document.getElementById('burger_menu');
  const menu = document.getElementById('menu');

  burgerMenuButton.addEventListener('click', function () {
    menu.classList.toggle('open');
    document.body.classList.toggle('no-scroll');
  });

  // Close the menu if clicked outside of it
  document.addEventListener('click', function (event) {
    if (!menu.contains(event.target) && !burgerMenuButton.contains(event.target)) {
      menu.classList.remove('open');
      document.body.classList.remove('no-scroll');
    }
  });
});

// Отзывы кадема ребрендеринг
document.addEventListener('DOMContentLoaded', function () {
  const reviewsWrapper = document.querySelector('.kadema__rebranding__reviews__swiper');
  if (reviewsWrapper) {
    const swiper = new Swiper('.kadema__rebranding__reviews__swiper', {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 10,
      pagination: {
        el: '.kadema__rebranding__reviews__swiper__pagination',
        clickable: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          slidesPerGroup: 1,
          spaceBetween: 20,
        },
        1260: {
          slidesPerView: 3,
          slidesPerGroup: 1,
          spaceBetween: 30,
        },
      },
    });
  }
});

// Превью гендир
document.addEventListener('DOMContentLoaded', function () {
  var videoPreview = document.getElementById('videoPreview');
  var video = document.getElementById('gendir_mission');

  function hideVideoPreview() {
    console.log('display none preview');
    if (videoPreview) {
      videoPreview.style.display = 'none';
    } else {
      console.error('videoPreview element not found');
    }
  }

  if (videoPreview && video) {
    videoPreview.addEventListener('click', function () {
      console.log('videoPreview clicked');
      video
        .play()
        .then(() => {
          hideVideoPreview();
        })
        .catch((error) => {
          console.error('Error playing video:', error);
        });
    });
  } else {
    console.error('videoPreview or video element not found');
  }
});




















