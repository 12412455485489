import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

document.addEventListener('DOMContentLoaded', function () {
  if (document.querySelector('.services')) {
    gsap.registerPlugin(ScrollTrigger);

    gsap.from('.services__list__item', {
      scrollTrigger: {
        trigger: '.services__list',
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none reverse',
      },
      opacity: 0,
      y: 50,
      duration: 1,
      stagger: 0.2,
    });
  }
});
