document.addEventListener('DOMContentLoaded', function () {
  const buttons = document.querySelectorAll('.kadema__rebranding__reviews__wrapper__item__expand');

  if (buttons.length > 0) {
    buttons.forEach((button) => {
      button.addEventListener('click', function () {
        const item = this.parentElement;
        const text = item.querySelector('.kadema__rebranding__reviews__wrapper__item__text');
        const isExpanded = text.classList.contains('expanded');
        const svg = this.querySelector('svg');
        const path = svg.querySelector('path');

        if (isExpanded) {
          text.classList.remove('expanded');
          this.querySelector('span').textContent = 'Читать полностью';
        } else {
          text.classList.add('expanded');
          this.querySelector('span').textContent = 'Скрыть';
        }
      });
    });
  }
});
